import React, { useContext, useEffect, useRef } from 'react';

import { CircularProgress } from '@mui/material';

import ConfigsContext from '../../Container/Config/ConfigsContext';
import { AuthConsumer } from '../../HO/Auth';
import { TranslationsProvider } from '../../Container/Language';
import Layout from './Layout';

const LayoutContext = (props) => {
  const { data, dataLoading } = useContext(ConfigsContext);
  const isConfigsMount = useRef(false);

  useEffect(() => {
    if (data && !isConfigsMount.current) {
      isConfigsMount.current = true;

      if (data.GA_ID) {
        try {
          import('react-ga').then((ReactGA) => {
            ReactGA.initialize(data.GA_ID);
            // move pageview to other useEffect ? after routed ?
            ReactGA.pageview(`${window.location.pathname}${window.location.search}`);
          });
        } catch (e) {
          console.log(e);
        }
      }

      if (data.GTM_ID) {
        try {
          import('react-gtm-module').then((TagManager) => {
            TagManager.initialize({
              gtmId: data.GTM_ID,
            });
          });
        } catch (e) {
          console.log(e);
        }
      }

      if (data.FBT_ID) {
        try {
          import('react-gtm-module').then((ReactPixel) => {
            ReactPixel.init(data.FBT_ID);
            // For tracking page view
            ReactPixel.pageView();
          });
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [data]);

  if (!data || dataLoading) {
    return (
      <div style={{ margin: '25px auto', textAlign: 'center' }}>
        <CircularProgress />
      </div>
    );
  }

  return (
    <AuthConsumer>
      {({ user }) => (
        <TranslationsProvider form={{ language: user ? user.language : data.language }}>
          <Layout {...props} />
        </TranslationsProvider>
      )}
    </AuthConsumer>
  );
};

export default LayoutContext;
